<template>
  <div class="accesses-container">
    <zem-card>
      <div>
        <template>
          <zem-card-title v-if="newElement" class="mb-0">Создание роли</zem-card-title>
          <template v-else>
            <zem-card-title>Роль: {{ currentItem['display_name'] }}</zem-card-title>
            <div class="block-id">
              <span class="block-id__label"></span>
              ID {{ currentItem['number'] }}
            </div>
            <div v-if="!closeAcc([3])" class="block-id">
              <span class="block-id__label second"></span>
              ID {{ currentItem['id'] }}
            </div>
          </template>
        </template>
      </div>
      <div class="tw-flex tw-gap-x-2">
        <template v-if="!edit && !newElement">
          <img
            v-if="$can('edit', 'role-update')"
            :src="require('@/assets/icons/edit.svg')"
            alt=""
            class="block-id__edit"
            @click="edit = true"
          />
          <img
            :src="require('@/assets/icons/close.svg')"
            alt=""
            class="block-id__delete tw-ml-0"
            @click="closeRightSidebar"
          />
        </template>
        <template v-else>
          <img
            :src="require('@/assets/icons/close.svg')"
            alt=""
            class="block-id__delete tw-ml-0 mobile-d"
            @click="closeRightSidebar"
          />
          <zem-link class="mobile-d-none" @click="closeRightSidebar">Отменить</zem-link>
          <zem-button :loading="loadingNewElement" @click="$emit(!edit ? 'on-create' : 'on-update')">
            Сохранить
          </zem-button>
        </template>
      </div>
    </zem-card>

    <div class="zem-collapse-table rows mt-3 mb-3">
      <div class="zem-collapse-table__row" style="grid-template-columns: 1fr 2fr">
        <div class="zem-collapse-table__column title mt-1">Уникальный псевдоним</div>
        <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
          {{ currentItem['name'] }}
        </div>
        <ZemInput
          v-else
          :value="currentItem['name']"
          class="mt-0"
          type="text"
          @input="e => (currentItem['name'] = e)"
        />
        <div class="zem-collapse-table__column title mt-1">Отображаемое название</div>
        <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
          {{ currentItem['display_name'] }}
        </div>
        <ZemInput
          v-else
          :value="currentItem['display_name']"
          class="mt-0"
          type="text"
          @input="e => (currentItem['display_name'] = e)"
        />
        <div class="zem-collapse-table__column title mt-1">Описание</div>
        <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
          {{ currentItem['description'] }}
        </div>
        <ZemInput
          v-else
          :value="currentItem['description']"
          class="mt-0"
          type="text"
          @input="e => (currentItem['description'] = e)"
        />
      </div>
    </div>

    <div v-if="!newElement && !$store.state.roles.isArchive" class="position-relative">
      <preloader-wrapper :show="schemaData.length === 0" class="mt-4" />
      <template v-for="(schema, schemaIndex) in schemaData">
        <zem-collapse :key="schemaIndex" :title="schema.section">
          <template v-for="(container, containerIndex) in schema['containers']">
            <zem-collapse
              v-if="schema['containers'].length > 0"
              :key="containerIndex"
              :arrow="false"
              :title="container.container"
              class="mt-0"
              not-border
            >
              <div class="zem-collapse-table rows">
                <div
                  class="zem-collapse-table__row"
                  style="grid-template-columns: repeat(1, 1fr); gap: 10px; margin-left: 19px"
                >
                  <template v-for="(permission, permissionIndex) in container.permissions">
                    <ZemCheckbox
                      :key="permissionIndex"
                      v-model="permission['is_assigned']"
                      :disabled="!edit || roleDisabled || currentItem.name === 'admin'"
                    >
                      <p v-tippy="{delay: 200, content: permission['description']}">
                        {{ permission['display_name'] }}
                      </p>
                    </ZemCheckbox>
                    <p class="text-normal text-muted">{{ permission['name'] }}</p>
                  </template>
                </div>
              </div>
            </zem-collapse>
          </template>
        </zem-collapse>
      </template>
    </div>
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard'
import ZemCardTitle from '@/components/ui/ZemCardTitle'
import ZemCollapse from '@/components/ui/ZemCollapse'
import ZemInput from '@/components/ui/ZemInput'
import ZemDropdownList from '@/components/ui/ZemDropdownList'
import ZemSelect from '@/components/ui/Select.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import {eventBus} from '@/main'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import ZemCheckbox from '@/components/ui/Checkbox.vue'
import {closeAcc} from '@/assets/scripts/scripts'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'

export default {
  components: {
    PreloaderWrapper,
    ZemPreloader,
    ZemCheckbox,
    ZemRadioButton,
    ZemSelect,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemLink,
    ZemButton,
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    newElement: {
      type: Boolean,
      default: false,
    },
    loadingNewElement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      newElementTitle: '',
      data: null,
      testElements: [
        {
          title: 'Название роли',
          value: 'Снабженец',
        },
        {
          title: 'Комментарий',
          value: 'Справочник + продукты',
        },
      ],
      schemaData: [],
      testOptions: [
        {
          title: 'Создавать',
          value: true,
        },
        {
          title: 'Отображать',
          value: false,
        },
        {
          title: 'Изменять',
          value: false,
        },
        {
          title: 'Удалять',
          value: false,
        },
        {
          title: 'Экспортировать',
          value: false,
        },
      ],
      options: [
        {
          title: 'Создавать',
          value: true,
        },
        {
          title: 'Отображать',
          value: false,
        },
        {
          title: 'Изменять',
          value: false,
        },
        {
          title: 'Удалять',
          value: false,
        },
        {
          title: 'Экспортировать',
          value: false,
        },
      ],
      loading: false,
      roleDisabled: true,
    }
  },
  watch: {
    currentItem() {
      this.roleDisabled = this.currentItem.name === this.$store.state.user.role
    },
  },
  methods: {
    closeAcc,
    closeRightSidebar() {
      this.edit = false
      this.newElement = false
      eventBus.$emit('new-state', false)
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
  },
}
</script>

<style lang="scss">
.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}

.accesses-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      img:not(:first-child) {
        margin-left: 8px;
      }

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.accesses-container {
  height: 100%;
  padding: 20px;
  overflow-y: auto;

  .block-id {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;

    &__edit {
      cursor: pointer;
    }

    &__label {
      width: 6px;
      height: 6px;
      background: #2eb67d;
      border-radius: 100px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.details-form {
  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
    margin-right: 20px;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-mine-shaft;
    flex: 1;
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__info-container:not(:first-child) {
    margin-top: 6px;
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
